import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import BlogPost from '../templates/blog-post';
import { graphql } from 'gatsby';
import CodeExample from '../components/CodeExample';
export const query = graphql`
  query($slug: String!) {
    mdx(fields: { slug: { eq: $slug } }) {
      frontmatter {
        title
        description
        promo
        noPic
      }
      fields {
        name
      }
    }
  }
`;
export const _frontmatter = {
  "title": "Grid Critters Launch",
  "date": "2017-11-20",
  "promo": "grids",
  "description": "The epic launch of my Grid Critters Mastery Game",
  "noPic": true,
  "color": "#00be2e"
};
const layoutProps = {
  query,
  _frontmatter
};
const MDXLayout = BlogPost;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h2>{`Welcome to the Future of Frontend Layout`}</h2>
    <p>{`It happened fast: just this year all the evergreen browsers (Edge, Firefox, Chrome, Safari) shipped support for CSS Grid. Bringing `}<a parentName="p" {...{
        "href": "/post/shiny-new-grid-tools/"
      }}>{`amazing new tools`}</a>{` to our craft. Powerful tools that are sitting in your browser, ready now for you to use. Grid layout will become your new default as it has for me. You'll begin to see nearly everything as a Grid - which it turns out most things are. I was actually shocked at how frequently I was able to leverage Grids for building `}<a parentName="p" {...{
        "href": "https://gridcritters.com"
      }}>{`Grid Critters`}</a>{` itself. `}</p>
    <p>{`The interior of Meg's shuttle? It's a Grid.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-css"
      }}>{`shuttle {
  display: grid;
  grid-template-columns: 25vw 50vw 25vw;
  grid-template-rows: 50vh 50vh;
  grid-template-areas: "left top right" "left bottom right";
}
`}</code></pre>
    <p><img parentName="p" {...{
        "src": "/gc/conversation.jpg",
        "alt": "shuttle interior is a Grid"
      }}></img></p>
    <p>{`The game layout is a Grid:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-css"
      }}>{`game {
  display: grid;
  grid-template-columns: repeat(2, 100px);
  grid-template-rows: repeat(3, 100px);
  align-content: space-evenly;
  justify-content: space-evenly;
}
`}</code></pre>
    <p><img parentName="p" {...{
        "src": "/gc/exercise-layout.jpg",
        "alt": "game layout is a Grid"
      }}></img></p>
    <p>{`Even areas like these actions were perfect for a Grid since `}<inlineCode parentName="p">{`grid-gaps`}</inlineCode>{` are amazing - they only apply space between the tracks which is way more convenient than using margin.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-css"
      }}>{`actions {
  display: grid;
  justify-self: end;
  align-self: start;
  grid-auto-flow: columns;
  grid-column-gap: 10px;
}
`}</code></pre>
    <p><img parentName="p" {...{
        "src": "/gc/buttons.jpg",
        "alt": "button container is a Grid"
      }}></img></p>
    <p>{`Grids are the present and the future of frontend layout and are going to completely change the way you work.`}</p>
    <h2>{`Don't Fumble Along`}</h2>
    <p>{`While Grid layout is insanely powerful, it does have a steep learning curve. There are `}<em parentName="p">{`over twenty`}</em>{` new CSS properties to learn, each with a bunch of options. And the trouble is - to achieve the level of proficiency you need, you're going to have to build a `}<em parentName="p">{`ton`}</em>{` of projects. You'll have to create literally dozens of UIs before it becomes second nature. Most devs will never get there - and you'll watch them constantly stopping what they're working on to look up cheat sheets and fumble around in the dev tools, clearly having no idea what they're doing. For the next five+ years. `}</p>
    <p>{`Don't be one of them. Become a master of your craft. Once you know Grid layout like the back of your hand you'll feel like a UI superhero. You'll be able to imagine a layout and build it with nothing getting in the way. You'll be able to prototype your ideas in a fraction of the time. You'll be able to create advanced layouts in very few lines of maintainable code. You'll stay in that sweet state of flow as you build your best work.`}</p>
    <p>{`I've prepared hundreds of levels for you in `}<a parentName="p" {...{
        "href": "https://gridcritters.com"
      }}>{`Grid Critters`}</a>{` - giving you explanations of every property and option in a way that will stick, and most importantly the deliberate practice you need to gain mastery over all of it. By the time you're done with this Mastery Game you'll have the experience of someone who's built hundreds of Grid layouts. I'm thrilled for you to feel what that's like.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      